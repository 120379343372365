import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTerminal = _resolveComponent("FormTerminal")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModal) = $event)),
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      style: { width: '60vw' },
      modal: true,
      contentClass: "wrap-modal-default"
    }, {
      default: _withCtx(() => [
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_FormTerminal, {
              key: 0,
              onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = false)),
              onOnSubmit: _cache[1] || (_cache[1] = (e) => {
        _ctx.$emit('onSubmit', e)
        _ctx.showModal = false
      })
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}