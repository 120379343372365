
import { ref, defineComponent, reactive } from 'vue'
import {
  get
} from 'lodash'
import HjpDataTable from '@/views/components/table/HjpDataTable.vue'
import moment from 'moment'

export default defineComponent({
  components: {
    HjpDataTable
  },

  setup() {
    const params = reactive({
      endpoint: 'management/v1/MsPrice/price-change-logs',
      columns: [{
        field: 'MainPrice',
        headerName: 'Harga',
        searchable: true,
        fieldSearch: 'harga',
        isRender: false
      },
      {
        field: 'ChangePriceName',
        headerName: 'Perubahan Harga',
        fieldSearch: 'channelId',
        isRender: false,
        searchable: false
      },
      {
        field: 'DateChanges',
        headerName: 'Tanggal Perubahan',
        searchable: false,
        isRender: true
      }
      ],
      showAction: false,
      showBtnAdd: false,
      showSearch: false,
      headerTitle: 'History Perubahan Harga'
    })
    return {
      params,
      moment
    }
  }
})
