import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6" }
const _hoisted_2 = { class: "top-section" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "bottom-section" }
const _hoisted_5 = { class: "grid grid-cols-3 gap-4 items-center" }
const _hoisted_6 = { class: "col-span-2" }
const _hoisted_7 = { class: "text-sm font-bold" }
const _hoisted_8 = { class: "flex justify-end" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PassengerForm = _resolveComponent("PassengerForm")!
  const _component_HjpDataList = _resolveComponent("HjpDataList")!
  const _component_ListActions = _resolveComponent("ListActions")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HjpDataList, {
      endpoint: _ctx.tabelParams.endpoint,
      headerTitle: _ctx.tabelParams.headerTitle,
      buttonTitle: _ctx.tabelParams.buttonTitle,
      confirmDialogDelete: _ctx.tabelParams.confirmDialogDelete,
      fieldSearch: ['code', 'type']
    }, {
      content: _withCtx(({ dataItems }) => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataItems, (data, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: "item-type-passenger"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(`small-box ${data.IsActive ? 'active' : 'non-active'}`)
                }, null, 2),
                _createElementVNode("div", _hoisted_3, _toDisplayString(data.Code), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(data.Type), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("i", {
                      class: "pi pi-ellipsis-h text-base cursor-pointer color-dark",
                      onClick: ($event: any) => (_ctx.showDropDown($event, data))
                    }, null, 8, _hoisted_9)
                  ])
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      form: _withCtx(() => [
        _createVNode(_component_PassengerForm)
      ]),
      _: 1
    }, 8, ["endpoint", "headerTitle", "buttonTitle", "confirmDialogDelete"]),
    _createVNode(_component_OverlayPanel, { ref: "op" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListActions, {
          edit: true,
          del: true,
          onDel: _ctx.onDelete,
          onEdit: _ctx.onEdit
        }, null, 8, ["onDel", "onEdit"])
      ]),
      _: 1
    }, 512)
  ]))
}