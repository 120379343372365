import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "mapDiv",
  ref: "mapDiv",
  class: "mapDiv"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputText, {
      class: "input-primary mb-2",
      id: "pac-input",
      placeholder: "Cari Disini..."
    }),
    _createElementVNode("div", _hoisted_1, null, 512)
  ]))
}