
import Dialog from 'primevue/dialog'
import { defineComponent, ref, computed, reactive } from 'vue'
import FormTerminal from './FormTerminal.vue'

export default defineComponent({
  name: 'ModalTerminal',
  components: {
    Dialog,
    FormTerminal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
  },
  emits: ['hideDialog', 'onSubmit'],
  /* eslint-disable-next-line */
  setup(props, { emit }: any) {
    const propertiesProps = reactive(props)
    const showModal = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    return {
      showModal
    }
  }
})
