
import {
  ref, watch, defineComponent, PropType, toRefs, reactive, computed, onMounted, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import OverlayPanel from 'primevue/overlaypanel'
import IResponse from '@/utils/interfaces/iResponse'
import ActivePriceForm from '@/views/pages/master-setting/price/active-price/Form.vue'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import HjpDataList from '@/views/components/list/HjpDataList.vue'

export default defineComponent({
  components: {
    // Card,
    // HjpSideBar,
    OverlayPanel,
    ListActions,
    // Paginator,
    ActivePriceForm,
    // InputIconRight,
    HjpCofirmModal,
    HjpDataList
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const {
      searchValue
    } = toRefs(props)
    const op = ref()
    const sortOp = ref()
    const router = useRouter()
    const store = useStore()
    const inputSearch = ref('')
    const dataRow = ref(null) as any

    const endpoint = '/management/v1/MsPrice'

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Hapus Harga?',
      subTitle: 'Menghapus Harga akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus Harga?',
      btnSubmitTitle: 'Hapus Harga',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    })

    const params = reactive({
      showHeader: false,
      endpoint,
      pageSize: 10,
      fieldSearch: ['name'],
      confirmDialogDelete: attrModalConfirmation,
      searchValue: searchValue.value
    })

    const showDropDown = (evt: any, item: any) => {
      dataRow.value = item
      op.value.toggle(evt)
    }

    const setDataRowOnRedux = async () => {
      store.dispatch('storeRowDataListDl', dataRow.value)
    }

    const showForm = () => {
      store.dispatch('setShowModalFormDl', true)
    }

    const onEdit = async () => {
      await setDataRowOnRedux()
      showForm()
      op.value.hide()
    }

    const onDelete = () => {
      setDataRowOnRedux()
      attrModalConfirmation.show = true
      op.value.hide()
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = (val: any = '') => {
      store.dispatch('showLoading')
      apiUseCase.delete(endpoint, dataRow.value?.Id)
        .then((res: IResponse) => {
          console.log('response delete', res)
          if (res.error) {
            $toast.add({
              severity: 'error',
              summary: 'Tidak dapat menghapus Harga',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              summary: 'Berhasil menghapus Harga!',
              detail: 'Harga sudah dihapus oleh sistem dan tidak dapat digunakan kembali.',
              group: 'bc',
              closable: false,
              life: 3000
            })
            store.dispatch('setReloadDataList')
          }
        }).catch((err: any) => {
          console.log('err delete', err)
        })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const redirectDetail = (PriceId: any) => {
      const Id = encryptDecriptMethods.encrypt(PriceId)
      router.push({
        name: 'master-price-detail',
        params: {
          id: Id
        }
      })
    }

    watch(searchValue, (newValue: string) => {
      params.searchValue = newValue
      setTimeout(() => {
        store.dispatch('setReloadDataList')
      }, 100)
    })

    return {
      onEdit,
      onDelete,
      showDropDown,
      sortOp,
      op,
      moment,
      inputSearch,
      attrModalConfirmation,
      onSubmitConfirmation,
      redirectDetail,
      hideDialogConfirmation,
      params
    }
  }
})
