import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HjpDataTable = _resolveComponent("HjpDataTable")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_HjpDataTable, _normalizeProps(_guardReactiveProps(_ctx.params)), {
      DateChanges: _withCtx(({ slotProps }) => [
        _createTextVNode(_toDisplayString(_ctx.moment(slotProps.data.DateChanges).format('DD MMMM YYYY')), 1)
      ]),
      _: 1
    }, 16)
  ]))
}