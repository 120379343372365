
import { defineComponent, ref, watch, onMounted, computed, getCurrentInstance, reactive } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import * as Yup from 'yup'
import { useRoute, useRouter } from 'vue-router'
import { Form, Field, FieldArray } from 'vee-validate'
import { IFixedExpensesForm } from '@/utils/interfaces/iFixedExpenses'
import apiUseCase from '@/usecase/apiUseCase'

import InputText from 'primevue/inputtext'
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import rgxExp from '@/utils/helpers/regExp'

const FixedExpensesForm = defineComponent({
  name: 'FixedExpensesForm',
  components: {
    InputText,
    Card,
    Button,
    Form,
    Field,
    FieldArray,
    // InputNumber,
    HjpCofirmModal
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const expenseId = ref(route.params?.id)
    const isEdit = ref<boolean>(route.params?.id ? true : false)
    const isLoading = ref(false)
    const dataForm = ref()
    const titleButton = ref('Simpan Pengeluaran')
    const dataSource = ref() as any

    const backRouteName = 'master-fixed-expenses'
    const endpoint = 'management/v1/MsExpenses'
    const endpointExpenseType = ref('management/v1/MsExpensesType')
    const moduleStore = 'hjpTable'

    const initialValues: IFixedExpensesForm = reactive({
      name: '',
      expenseDetail: [
        {
          idType: '',
          type: '',
          nominal: 0
        }
      ],
    })

    const schema = Yup.object().shape({
      name: Yup.string()
        .required('Nama Pengeluaran harus diisi!')
        .max(50, 'Nama maksimal 50 karakter!')
        .matches(rgxExp.notSpaceInFirstChar, 'Nama Pengeluaran tidak valid!'),
    })

    const attrModalConfirmation = reactive({
      show: false,
      title: '',
      subTitle: '',
      btnSubmitTitle: '',
      imgContent: require('@/assets/img/modal-confirmation/expenses-type-add-update.svg')
    })

    const checkAddOrUpdate = () => {
      if (expenseId.value) {
        attrModalConfirmation.title = 'Simpan Perubahan Pengeluaran?'
        attrModalConfirmation.subTitle = 'Pastikan data pengeluaran sudah benar dan sesuai dengan yang Anda inginkan.'
        attrModalConfirmation.btnSubmitTitle = titleButton.value
        isEdit.value = true
        // if (store.state[moduleStore].modal.data.Id) {
        //   console.log('aasdasd')
        // }
      } else {
        attrModalConfirmation.title = 'Simpan Pengeluaran '
        attrModalConfirmation.subTitle = 'Pastikan data pengeluaran sudah benar dan sesuai dengan yang Anda inginkan.'
        attrModalConfirmation.btnSubmitTitle = titleButton.value
      }
    }

    const onSubmit = (values: any) => {
      const details = values.expenseDetail.map((x: any) => ({
        MsExpensesTypeId: x.idType,
        Nominal: !x.nominal ? 0 : x.nominal
      }))
      console.log('values', values)
      dataForm.value = {
        Name: values.name,
        ExpenseDetails: details
      }
      console.log('dataform', dataForm.value)
      attrModalConfirmation.show = true
    }

    const onSubmitConfirmation = async () => {
      console.log('dataForm', dataForm)
      console.log('endpoint', endpoint)
      const toastMessage = isEdit.value ? 'Berhasil mengubah Pengeluaran' : 'Berhasil menambahkan Pengeluaran'
      const toastDetailMesssage = isEdit.value ? 'Pengeluaran sudah diubah dan dapat digunakan pada fitur lain.' : 'Pengeluaran sudah ditambahkan dan dapat digunakan pada fitur lain.'
      const response = !isEdit.value ? await apiUseCase.postOrPut(endpoint, null, dataForm.value) : await apiUseCase.postOrPut(endpoint, expenseId.value, dataForm.value)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataTable', true)
        $toast.add({
          severity: 'success',
          summary: toastMessage,
          detail: toastDetailMesssage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        // eslint-disable-next-line no-use-before-define
        onBack()
      } else {
        // store.dispatch('setShowModal', false)
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      // eslint-disable-next-line no-use-before-define
      hideDialogConfirmation()
    }

    const hideDialogConfirmation = () => {
      console.log('hide')
      attrModalConfirmation.show = false
    }

    const getAllExpenseType = () => {
      isLoading.value = true
      const params = '?pageSize=10000&activeOnly=true'
      apiUseCase.get(`${endpointExpenseType.value}${params}`).then((response) => {
        // console.log('response', response)
        initialValues.expenseDetail = response.result.map((x: any) => ({
          idType: x.Id,
          type: x.Name,
          nominal: 0
        }))
        if (isEdit.value) {
          // eslint-disable-next-line no-use-before-define
          getSingleExpense()
        } else {
          console.log('dataSource', initialValues)
          isLoading.value = false
        }
        // paginate.totalRecords = response.count
        // console.log('paginate', paginate)
        // isLoading.value = false
      }).catch((err: any) => {
        // isLoading.value = false
      })
    }

    const getSingleExpense = () => {
      apiUseCase.get(`${endpoint}/${expenseId.value}`).then((response) => {
        dataSource.value = response.result
        // eslint-disable-next-line no-use-before-define
        setValue()
      }).catch((err: any) => {
        console.log(err)
      })
    }

    const setValue = () => {
      // console.log('initvalue', initialValues)
      // console.log('data', dataSource.value)
      initialValues.name = dataSource.value.Name
      for (let i = 0; i < initialValues.expenseDetail.length; i++) {
        // console.log('i', i)
        for (let x = 0; x < dataSource.value.Details.length; x++) {
          // eslint-disable-next-line eqeqeq
          // console.log('cek', initialValues.expenseDetail[i].idType === dataSource.value.Details[x].ExpenseTypeId)
          if (initialValues.expenseDetail[i].idType === dataSource.value.Details[x].ExpenseTypeId) {
            // console.log(initialValues.expenseDetail[i].idType)
            // console.log(dataSource.value.Details[x].ExpenseTypeId)
            // console.log(dataSource.value.Details[x].Nominal)
            initialValues.expenseDetail[i].nominal = dataSource.value.Details[x].Nominal
            // console.log(initialValues.expenseDetail[i].nominal)
          }
        }
      }
      // console.log('newInitVal', initialValues)
      isLoading.value = false
    }

    const handleChange = (value: any) => {
      console.log('value', value)
    }

    const onBack = () => {
      store.dispatch('storeRowDataTable', null)
      router.push({
        name: backRouteName
      })
    }

    const test = (val: any) => {
      console.log('val', val)
    }

    onMounted(() => {
      console.log('isEdit', isEdit.value)
      getAllExpenseType()
      if (isEdit.value) {
        titleButton.value = 'Simpan perubahan'
      }
      checkAddOrUpdate()
    })

    return {
      isEdit,
      initialValues,
      schema,
      onSubmit,
      isLoading,
      onBack,
      handleChange,
      attrModalConfirmation,
      onSubmitConfirmation,
      hideDialogConfirmation,
      test
    }
  }
})
export default FixedExpensesForm
