import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "price" }
const _hoisted_2 = { class: "mb-6" }
const _hoisted_3 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_HjpTabView = _resolveComponent("HjpTabView")!
  const _component_ActivePrice = _resolveComponent("ActivePrice")!
  const _component_HistoryChangePrice = _resolveComponent("HistoryChangePrice")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "card-no-shadow wrap-hjp-data-list" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HeaderHjpTable, _mergeProps(_ctx.paramsHeader, {
            onRedirectAdd: _ctx.onClickBtnAdd,
            onOnSearch: _ctx.onSearch
          }), null, 16, ["onRedirectAdd", "onOnSearch"])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_HjpTabView, {
          onTabsChange: _ctx.tabsChange,
          tabHeader: _ctx.tabHeader
        }, null, 8, ["onTabsChange", "tabHeader"]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.positionTabs === 0)
            ? (_openBlock(), _createBlock(_component_ActivePrice, {
                key: 0,
                searchValue: _ctx.searchValue
              }, null, 8, ["searchValue"]))
            : (_ctx.positionTabs === 1)
              ? (_openBlock(), _createBlock(_component_HistoryChangePrice, { key: 1 }))
              : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}