
import {
  ref,
  defineComponent,
  computed,
  getCurrentInstance,
  reactive
} from 'vue'
import { useStore } from 'vuex'
import Card from 'primevue/card'
import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import ActivePrice from '@/views/pages/master-setting/price/active-price/ActivePrice.vue'
import HistoryChangePrice from '@/views/pages/master-setting/price/history-price/Table.vue'

export default defineComponent({
  components: {
    Card,
    HjpTabView,
    HeaderHjpTable,
    ActivePrice,
    HistoryChangePrice
  },

  setup() {
    const positionTabs = ref(0)
    const app = getCurrentInstance()
    const searchValue = ref<string>('')
    const tabHeader = [
      { title: 'Harga Aktif & Berlaku', value: 0 },
      { title: 'History Perubahan Harga', value: 1 }
    ]
    const paramsHeader = reactive({
      titleHeader: 'Master Harga',
      titleButton: 'Tambah Harga',
      showAddBtn: true,
      showSearch: true,
      placeholder: 'Cari Disini...'
    })

    const store = useStore()

    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const showModalFormFunction = () => {
      showModalForm.value = true
    }

    const tabsChange = (val: any) => {
      positionTabs.value = val
      if (val === 0) {
        paramsHeader.showSearch = true
        paramsHeader.showAddBtn = true
      } else if (val === 1) {
        paramsHeader.showSearch = false
        paramsHeader.showAddBtn = false
      }
    }

    const onSearch = (search: string) => {
      searchValue.value = search
    }

    const onClickBtnAdd = (val: any) => {
      store.dispatch('flushRowDataTable')
      showModalForm.value = true
    }

    return {
      tabsChange,
      tabHeader,
      positionTabs,
      onClickBtnAdd,
      hideModalForm,
      showModalForm,
      showModalFormFunction,
      onSearch,
      searchValue,
      paramsHeader
    }
  }
})
