
import Button from 'primevue/button'
import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import vSelect from 'vue-select'
import * as Yup from 'yup'
import { useStore } from 'vuex'
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
// import { values } from 'lodash'

export default {
  props: {
    originList: {
      default: () => [],
    },
  },

  components: {
    Form,
    vSelect,
    Button
  },

  setup(props: any) {
    const routeEndpoint = '/management/v1/MsRoute'
    const priceDetailEndpoint = '/management/v1/MsDetilPrice'

    const { originList } = toRefs(props)
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const initialValues = reactive({
      origin: null
    })
    const dataDestination = ref([]) as any
    const dataDestinationTemp = ref([])
    const dataParentPrice = ref(store.state.hjpTable.modal.data)
    const passengerId = ref(store.state.selectedPassenger)
    const priceChangeId = ref(store.state.selectedPriceChange)
    const dataTerminals = ref([]) as any
    const dataOption = ref([])

    // const schema = Yup.object().shape({
    //   origin: Yup.object()
    //     .required('Terminal Asal tidak boleh kosong')
    //     // eslint-disable-next-line consistent-return
    //     .test('unique-name', 'Terminal Asal sudah digunakan', (val: any) => {
    //       console.log('val', val.value)
    //       console.log('originList', originList.value)
    //       console.log(originList.value.includes(val.value))
    //       if (!val) {

    //       }
    //       if

    //       return !val && originList.value.includes(val.value)
    //       // try {
    //       //   const res = await apiUseCase.get(`${endpoint}${returnUrlPrams({
    //       //     search: val,
    //       //     filterField: ['name']
    //       //   })}`)
    //       //   const codeFilter = res.result.filter((x: any) => x.Name === val)
    //       //   if (route.params.id) {
    //       //     return codeFilter.length === 0 || val === formValues.value.Name
    //       //   } return codeFilter.length === 0
    //       // } catch (error) {
    //       //   return false
    //       // }
    //     }),
    // })

    const getAllTerminalInRoute = () => {
      apiUseCase.get(`${routeEndpoint}/${dataParentPrice.value.MsRouteId}`).then((response) => {
        console.log('resRoute', response)
        dataTerminals.value = response.result.Terminal.filter((x: any) => !originList.value.includes(x.TerminalId))
        dataOption.value = dataTerminals.value.map((x: any) => ({
          label: x.Name,
          value: x.TerminalId,
          order: x.Order
        }))
        if (dataOption.value.length > 0) {
          dataOption.value.pop()
        }
        console.log('dataOption', dataOption.value)
      })
    }

    const getDestination = (data: any) => {
      console.log('bweeehhh', data)
      dataDestinationTemp.value = dataTerminals.value.filter((x: any) => x.Order > data.order)
      console.log('dataDestination', dataDestinationTemp.value)
    }

    const hideSidebar = () => {
      console.log('aaa')
      store.dispatch('setShowModalFormDl', false)
      console.log('store', store)
    }

    const prosesSubmit = async (dataForm: any) => {
      const toastMessage = 'Terminal Asal dan Tujuan berhasil ditambahkan!'
      const response = await apiUseCase.postOrPut(`${priceDetailEndpoint}/fill-origin`, null, dataForm)
      console.log('response', response)
      if (!response.error) {
        store.dispatch('setShowModal', false)
        store.dispatch('setReloadDataList', true)
        $toast.add({
          severity: 'success',
          detail: toastMessage,
          group: 'bc',
          closable: false,
          life: 3000
        })
        hideSidebar()
        // store.dispatch('setReloadDataTable')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
    }

    const onSubmit = (values: any) => {
      store.dispatch('showLoading')
      // eslint-disable-next-line array-callback-return
      dataDestinationTemp.value.map((x: any) => {
        dataDestination.value.push(x.TerminalId)
      })
      console.log('dataDes', dataDestination.value)
      console.log('val', values)
      // console.log('destination', dataDestination.value)
      if (values.origin) {
        const dataForm = {
          MsPriceId: priceChangeId.value,
          MsPassengerId: passengerId.value,
          Origin: values.origin.value,
          Destination: dataDestination.value
        }
        console.log('dataForm', dataForm)
        prosesSubmit(dataForm)
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Tidak ada Terminal Asal yang dipilih',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    onMounted(async () => {
      console.log('store', store.state.hjpTable.modal.data)
      const titleForm = 'Tambah'
      store.dispatch('setTitleFormSidebar', `${titleForm} Asal & Tujuan`)
      getAllTerminalInRoute()
      console.log('originList', originList.value)
    })

    return {
      // schema,
      onSubmit,
      getDestination,
      initialValues,
      dataDestinationTemp,
      dataOption,
      hideSidebar
    }
  }
}

